import { api } from "../utils/request";

async function getTemplates() {
	const res = await api({
		method: "get",
		url: "/api/templates",
	});

	return res;
}

async function getFonts() {
	const res = await api({
		method: "get",
		url: "/api/templates/fonts",
	});

	return res;
}

async function generateImages(data) {
	const res = await api({
		method: "post",
		url: "/api/templates/generate",
		data,
		headers: { "Content-Type": "multipart/form-data" },
	});

	return res;
}

async function addTemplate(template) {
	const res = await api({
		method: "post",
		url: "/api/templates",
		data: template,
	});

	return res;
}

async function editTemplate(template) {
	const res = await api({
		method: "put",
		url: `/api/templates/${template._id}`,
		data: template,
	});

	return res;
}

async function deleteTemplate(id) {
	const res = await api({
		method: "delete",
		url: `/api/templates/${id}`,
	});

	return res;
}

export { getTemplates, getFonts, generateImages, addTemplate, editTemplate, deleteTemplate };
